import { useRef, useCallback, useEffect, RefObject } from "react";

const useClickOutside = <T extends HTMLElement = HTMLDivElement>(activated: boolean, togglePopup: () => void, useClick?: string): RefObject<T> => {
    const ref = useRef<T>(null);
    const handleClickOutside = useCallback(
        (event: MouseEvent | TouchEvent) => {
            if (activated && ref.current && !ref.current.contains(event.target as Node)) {
                togglePopup();
            }
        },
        [activated, ref, togglePopup]
    );

    function getEventType(useClick: string | undefined) {
        switch (useClick) {
            case "touchstart":
                return "touchstart";
            case "click":
                return "click";
            default:
                return "mousedown";
        }
    }

    useEffect(() => {
        const timeout = setTimeout(() => {
            document.addEventListener(`${getEventType(useClick)}`, handleClickOutside);
        }, 0);
        return () => {
            clearTimeout(timeout);
            document.removeEventListener(`${getEventType(useClick)}`, handleClickOutside);
        };
    }, [handleClickOutside, useClick]);

    return ref;
};

export default useClickOutside;
