import { userModelSelector, guestModeSelector } from "../../../../state/selectors/userModel";
import { useRecoilState } from "recoil";
import { useNavigate } from "react-router-dom";
import {
    ColumnNavbarProfileImage,
    ColumnNavbarDesktopListYourPlaceWrapper,
    ColumnNavbarProfileWrapper,
    ColumnNavbarProfileImageButton,
    ColumnNavbarDesktopListYourPlaceIcon,
    ColumnNavbarHostInfoWrapper,
    ColumnNavbarHostInfoTitle,
    ColumnNavbarHostInfoSubtitle,
    ColumnNavbarHostModelWrapper,
} from "../navbarStyling";
import { useState, useEffect } from "react";
import NavbarMenu from "../navbarDropDownMenu";
import constructOptimisedProtectedImageUrl from "../../../../utils/imageOptimisation/constructOptimisedProtectedImageUrl";

interface ColumnNavbarProfileComponentsProps {
    navbarMenu: boolean;
    setNavbarMenu: React.Dispatch<React.SetStateAction<boolean>>;
    setNavbarSearchMenuActivated: React.Dispatch<React.SetStateAction<boolean>>;
    columnNavText: boolean;
    setColumnNavText: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function ColumnNavbarProfileComponents({ navbarMenu, setNavbarMenu, setNavbarSearchMenuActivated, columnNavText, setColumnNavText }: ColumnNavbarProfileComponentsProps) {
    const navigate = useNavigate();
    const [userModel, setUserModel] = useRecoilState(userModelSelector);
    const [guestMode, setGuestMode] = useRecoilState(guestModeSelector);

    const [profilePic, setProfilePic] = useState("https://anystay-assets.s3.ap-southeast-2.amazonaws.com/Settings+page/Profile+photo+placeholder.png");
    useEffect(() => {
        if (userModel && userModel.profilePicture) {
            const profilePic = constructOptimisedProtectedImageUrl(userModel.profilePicture, userModel.identityID, 100);
            setProfilePic(profilePic);
        } else {
            setProfilePic("https://anystay-assets.s3.ap-southeast-2.amazonaws.com/Settings+page/Profile+photo+placeholder.png");
        }
    }, [userModel]);

    const renderListYourPlaceOrSwitchToStaying = () => {
        if (userModel && !guestMode) {
            return (
                <ColumnNavbarDesktopListYourPlaceWrapper
                    onClick={() => {
                        navigate("/");
                        setColumnNavText(false);
                    }}
                >
                    <ColumnNavbarDesktopListYourPlaceIcon src={"https://anystay-assets.s3.ap-southeast-2.amazonaws.com/Universal+icons/Group.svg"} alt={"change mode"} />
                    {(columnNavText || navbarMenu) && (
                        <ColumnNavbarHostModelWrapper>
                            <ColumnNavbarHostInfoTitle>Switch to staying</ColumnNavbarHostInfoTitle>
                        </ColumnNavbarHostModelWrapper>
                    )}
                </ColumnNavbarDesktopListYourPlaceWrapper>
            );
        }
    };
    return (
        <ColumnNavbarProfileWrapper isHidden={columnNavText || navbarMenu}>
            {renderListYourPlaceOrSwitchToStaying()}
            <ColumnNavbarProfileImageButton
                onClick={() => {
                    setNavbarSearchMenuActivated(false);
                    setNavbarMenu(true);
                }}
            >
                <ColumnNavbarProfileImage src={profilePic} alt="profile picture" />
                {(columnNavText || navbarMenu) && (
                    <ColumnNavbarHostInfoWrapper>
                        <ColumnNavbarHostInfoTitle>{userModel && userModel.firstName}</ColumnNavbarHostInfoTitle>
                        <ColumnNavbarHostInfoSubtitle>{userModel && userModel.userIDConfirmed ? "Verified host" : "Host"}</ColumnNavbarHostInfoSubtitle>
                    </ColumnNavbarHostInfoWrapper>
                )}
            </ColumnNavbarProfileImageButton>
            {navbarMenu && <NavbarMenu closeNavbarMenu={() => setNavbarMenu(false)} navbarMenu={navbarMenu} setNavbarMenu={setNavbarMenu} isColumn={true} setColumnNavText={setColumnNavText} />}
        </ColumnNavbarProfileWrapper>
    );
}
