import { atom } from "recoil";

export const mobileSearchMenuActivatedState = atom<boolean>({
    key: "mobileSearchMenuActivatedState",
    default: false,
});
export const navbarSearchMenuActivatedState = atom<boolean>({
    key: "navbarSearchMenuActivatedState",
    default: false,
});

export const signInMenuActivatedState = atom<boolean>({
    key: "signInMenuActivatedState",
    default: false,
});

export const navbarMenuActivatedState = atom<boolean>({
    key: "navbarMenuActivatedState",
    default: false,
});

export const navbarSearchbarPopTypes = atom<string>({
    key: "navbarSearchbarPopTypes",
    default: "",
});

export const navbarSearchFilterState = atom<boolean>({
    key: "navbarSearchFilterState",
    default: false,
});

export const navbarSearchFilterNumberBubble = atom<number>({
    key: "navbarSearchFilterNumberBubble",
    default: 0,
});
