import {
    NavbarMenuWrapper,
    NavbarMenuBtn,
    NavbarMenuItem,
    NavbarMenuDivider,
    NavbarMenuItemMainIcon,
    NavbarMenuItemMainItemOutsideLink,
    NavbarMenuRedDot,
    NavbarMenuTextWrapper,
    NavbarMenuTextItemWrapper,
    NavbarMenuText1,
    NavbarMenuText2,
} from "./navbarStyling";
import { userModelSelector, guestModeSelector } from "../../../state/selectors/userModel";
import { userHostModelSelector, userHostConversationsModelSelector } from "../../../state/selectors/userHostModel";
import { signInMenuActivatedSelector } from "../../../state/selectors/navbarToggle";
import { useRecoilState, useRecoilValue } from "recoil";
import { logoutUser } from "../../../utils/auth/logoutUser";
import useClickOutside from "../../../hook/useClickOutside";
import { searchFiltersSelector } from "../../../state/selectors/searchQuery";
import { isUserChangePriceSelector } from "../../../state/selectors/isUserChangePrice";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { ColorTheme } from "../../globaStyledComponents/globalStyledComponentsStyling";
interface NavbarDropDownMenuProps {
    closeNavbarMenu: () => void;
    navbarMenu: boolean;
    setNavbarMenu: React.Dispatch<React.SetStateAction<boolean>>;
    isColumn?: boolean;
    setColumnNavText?: React.Dispatch<React.SetStateAction<boolean>>;
    style?: React.CSSProperties;
}

export default function NavbarDropDownMenu({ closeNavbarMenu, navbarMenu, setNavbarMenu, isColumn, setColumnNavText, style }: NavbarDropDownMenuProps) {
    const [userModel, setUserModel] = useRecoilState(userModelSelector);
    const [userHostModel, setUserHostModel] = useRecoilState(userHostModelSelector);
    const userHostConversations = useRecoilValue(userHostConversationsModelSelector);
    const [guestMode, setGuestMode] = useRecoilState(guestModeSelector);
    const [signInMenuActivated, setSignInMenuActivated] = useRecoilState(signInMenuActivatedSelector);

    const [searchFilters, setSearchFilters] = useRecoilState(searchFiltersSelector);
    const [isUserChangePrice, setIsUserChangePrice] = useRecoilState(isUserChangePriceSelector);

    const location = useLocation();
    const navigate = useNavigate();

    const wrapperRef = useClickOutside(
        navbarMenu,
        () => {
            setNavbarMenu(false);
        },
        "click"
    );
    const renderSwitchOrBecomeHostBtn = () => {
        if (userHostModel) {
            const renderSwitchToHost = userHostModel.listingModelNumber && userHostModel.listingModelNumber > 0;
            return (
                <NavbarMenuItem
                    to={renderSwitchToHost ? "/dashboard" : "/list-your-place"}
                    onClick={async () => {
                        closeNavbarMenu();
                    }}
                    style={{ padding: "13px 20px" }}
                >
                    {renderSwitchToHost ? "Switch to hosting" : "Become a host"}
                </NavbarMenuItem>
            );
        }
    };

    if (!userModel) {
        return (
            <NavbarMenuWrapper ref={wrapperRef} style={style}>
                {window.innerWidth < 600 && <NavbarMenuDivider style={{ margin: 0 }} />}
                <NavbarMenuBtn
                    onClick={() => {
                        setSignInMenuActivated(true);
                        closeNavbarMenu();
                    }}
                >
                    Sign up
                </NavbarMenuBtn>
                <NavbarMenuBtn
                    onClick={() => {
                        setSignInMenuActivated(true);
                        closeNavbarMenu();
                    }}
                >
                    Log in
                </NavbarMenuBtn>
                <NavbarMenuDivider />
                <NavbarMenuItem
                    to={"/list-your-place"}
                    onClick={() => {
                        closeNavbarMenu();
                    }}
                    style={{ padding: "13px 20px" }}
                >
                    Become a host
                </NavbarMenuItem>
                <NavbarMenuItemMainItemOutsideLink target={window.innerWidth > 768 ? "_blank" : ""} href="https://help.anystay.com.au" style={{ gap: "12px" }}>
                    Help center
                </NavbarMenuItemMainItemOutsideLink>
                <NavbarMenuDivider />
                <NavbarMenuTextItemWrapper
                    to="/referral-page"
                    onClick={() => {
                        closeNavbarMenu();
                    }}
                >
                    <NavbarMenuTextWrapper>
                        <NavbarMenuText1>Refer a friend</NavbarMenuText1>
                        <NavbarMenuText2>Invite friends and earn</NavbarMenuText2>
                    </NavbarMenuTextWrapper>
                    <NavbarMenuRedDot />
                </NavbarMenuTextItemWrapper>
            </NavbarMenuWrapper>
        );
    } else if (guestMode) {
        return (
            <NavbarMenuWrapper ref={wrapperRef} style={style}>
                {window.innerWidth < 600 && <NavbarMenuDivider style={{ margin: 0 }} />}
                <NavbarMenuItem
                    to={
                        window.innerWidth < 768
                            ? `/your-inbox/${userModel.id}`
                            : userModel.ConversationModels.items.length > 0
                              ? `/conversation/${userModel.ConversationModels.items.at(-1)!.id}`
                              : "/conversation/null"
                    }
                    onClick={() => {
                        closeNavbarMenu();
                    }}
                    style={{ padding: "12px 20px" }}
                >
                    <NavbarMenuItemMainIcon width="24" height="24" viewBox="0 0 24 24" fill="none" aria-label={"Messages"} xmlns="http://www.w3.org/2000/svg">
                        <mask id="path-1-inside-1_54966_12034" fill="white">
                            <path d="M0 6.24062C0 4.0562 1.49012e-07 2.96398 0.435974 2.12965C0.819467 1.39574 1.43139 0.79906 2.18404 0.425117C3.03968 1.49012e-07 4.15979 0 6.4 0H17.6C19.8403 0 20.9603 1.49012e-07 21.816 0.425117C22.5687 0.79906 23.1805 1.39574 23.564 2.12965C24 2.96398 24 4.0562 24 6.24062V13.2613C24 15.4458 24 16.5379 23.564 17.3723C23.1805 18.1063 22.5687 18.7029 21.816 19.0768C20.9603 19.5019 19.8403 19.5019 17.6 19.5019H14.2449C13.4129 19.5019 12.9969 19.5019 12.5989 19.5816C12.2459 19.6522 11.9043 19.7691 11.5833 19.929C11.2216 20.1092 10.8967 20.3626 10.2469 20.8694L7.06635 23.3506C6.51156 23.7834 6.23417 23.9997 6.00072 24C5.79769 24.0003 5.60563 23.9102 5.47897 23.7554C5.33333 23.5776 5.33333 23.2312 5.33333 22.5384V19.5019C4.09337 19.5019 3.47339 19.5019 2.96472 19.3691C1.58436 19.0084 0.50616 17.957 0.136293 16.6111C8.9407e-08 16.1151 0 15.5105 0 14.3014V6.24062Z" />
                        </mask>
                        <path
                            d="M0 6.24062C0 4.0562 1.49012e-07 2.96398 0.435974 2.12965C0.819467 1.39574 1.43139 0.79906 2.18404 0.425117C3.03968 1.49012e-07 4.15979 0 6.4 0H17.6C19.8403 0 20.9603 1.49012e-07 21.816 0.425117C22.5687 0.79906 23.1805 1.39574 23.564 2.12965C24 2.96398 24 4.0562 24 6.24062V13.2613C24 15.4458 24 16.5379 23.564 17.3723C23.1805 18.1063 22.5687 18.7029 21.816 19.0768C20.9603 19.5019 19.8403 19.5019 17.6 19.5019H14.2449C13.4129 19.5019 12.9969 19.5019 12.5989 19.5816C12.2459 19.6522 11.9043 19.7691 11.5833 19.929C11.2216 20.1092 10.8967 20.3626 10.2469 20.8694L7.06635 23.3506C6.51156 23.7834 6.23417 23.9997 6.00072 24C5.79769 24.0003 5.60563 23.9102 5.47897 23.7554C5.33333 23.5776 5.33333 23.2312 5.33333 22.5384V19.5019C4.09337 19.5019 3.47339 19.5019 2.96472 19.3691C1.58436 19.0084 0.50616 17.957 0.136293 16.6111C8.9407e-08 16.1151 0 15.5105 0 14.3014V6.24062Z"
                            stroke={ColorTheme.Black.color}
                            stroke-width="3.2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            mask="url(#path-1-inside-1_54966_12034)"
                        />
                    </NavbarMenuItemMainIcon>
                    Messages
                </NavbarMenuItem>
                <NavbarMenuItem
                    to={`/your-stays/${userModel?.id}`}
                    onClick={() => {
                        closeNavbarMenu();
                    }}
                    style={{ padding: "12px 20px" }}
                >
                    <NavbarMenuItemMainIcon width="24" height="24" viewBox="0 0 24 24" fill="none" aria-label={"Trips icon"}>
                        <path
                            d="M23.3055 0L24 1.43547L22.099 2.41814V22.3943C22.099 23.2405 21.4651 23.9342 20.6611 23.9952L20.5453 24H3.45465C2.63585 24 1.96465 23.3449 1.90561 22.514L1.90095 22.3943V12.855L0.694503 13.478L0 12.0426L1.90095 11.0607V4.73192H3.45465V10.2578L23.3055 0ZM20.5453 3.22098L3.45465 12.0522V22.3943H8.11575V14.366C8.11575 13.5198 8.74966 12.8261 9.5537 12.7643L9.66945 12.7603H14.3305C15.1493 12.7603 15.8205 13.4154 15.8804 14.2463L15.8842 14.366V22.3943H20.5453V3.22098ZM14.3305 14.366H9.66945V22.3943H14.3305V14.366Z"
                            fill={ColorTheme.Black.color}
                        />
                    </NavbarMenuItemMainIcon>
                    Trips
                </NavbarMenuItem>
                <NavbarMenuItem
                    to={`/saved-listings/${userModel?.id}`}
                    onClick={() => {
                        closeNavbarMenu();
                    }}
                    style={{ padding: "12px 20px" }}
                >
                    <NavbarMenuItemMainIcon width="24" height="24" viewBox="0 0 28 28" fill="none" aria-label={"Saved icon"} xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M2.26061 13.3918C5.07331 17.4376 8.89663 20.4197 12.8238 23.2792C12.9722 23.3873 13.373 23.3224 13.5518 23.1927C16.4106 21.1187 19.1902 18.9466 21.5963 16.3436C23.5642 14.2148 25.3072 11.9361 26.0237 9.04917C26.8138 5.86965 25.4788 2.5114 22.8246 0.914431C20.2484 -0.634975 16.9311 -0.189611 14.4629 2.05451L14.4198 2.09405C14.1243 2.36677 13.8509 2.66287 13.5765 2.96004C13.443 3.10465 13.3093 3.2495 13.1726 3.39204C13.0119 3.23609 12.8591 3.08852 12.7119 2.94629C12.4578 2.7008 12.2197 2.47076 11.9833 2.23899C11.949 2.20545 11.9146 2.17227 11.8799 2.13945C9.70682 0.0822306 6.68254 -0.554266 4.17227 0.529602C1.6306 1.62644 -0.0576046 4.36492 0.00150402 7.35708C0.0461959 9.62714 1.00058 11.5816 2.26061 13.3918Z"
                            stroke={ColorTheme.Black.color}
                            stroke-width="2"
                            transform="translate(1, 1)"
                        />
                    </NavbarMenuItemMainIcon>
                    Wishlists
                </NavbarMenuItem>
                <NavbarMenuDivider />
                {renderSwitchOrBecomeHostBtn()}
                <NavbarMenuItemMainItemOutsideLink target={window.innerWidth > 768 ? "_blank" : ""} href="https://help.anystay.com.au" style={{ gap: "12px" }}>
                    Help center
                </NavbarMenuItemMainItemOutsideLink>
                <NavbarMenuItem
                    to="/settings"
                    onClick={() => {
                        closeNavbarMenu();
                    }}
                >
                    Profile
                </NavbarMenuItem>
                <NavbarMenuDivider />
                <NavbarMenuTextItemWrapper
                    to="/referral-page"
                    onClick={() => {
                        closeNavbarMenu();
                    }}
                >
                    <NavbarMenuTextWrapper>
                        <NavbarMenuText1>Refer a friend</NavbarMenuText1>
                        <NavbarMenuText2>Invite friends and earn</NavbarMenuText2>
                    </NavbarMenuTextWrapper>
                    <NavbarMenuRedDot />
                </NavbarMenuTextItemWrapper>
                <NavbarMenuItem
                    to="/"
                    onClick={async () => {
                        await logoutUser();
                        closeNavbarMenu();
                        navigate("/");
                    }}
                >
                    Log out
                </NavbarMenuItem>
            </NavbarMenuWrapper>
        );
    } else if (!guestMode) {
        return (
            <NavbarMenuWrapper ref={wrapperRef} isColumn={isColumn} style={style}>
                {(location.pathname.includes("/settings") ||
                    location.pathname.includes("/security-settings") ||
                    location.pathname.includes("/edit-profile") ||
                    location.pathname.includes("/payment-settings") ||
                    location.pathname.includes("/payout-settings") ||
                    location.pathname.includes("/host/profile") ||
                    location.pathname.includes("/host-referral-page")) && (
                    <>
                        <NavbarMenuItem
                            to={
                                window.innerWidth < 768
                                    ? `/your-host-inbox/${userModel?.id}`
                                    : userHostConversations && userHostConversations.length > 0
                                      ? `/host-conversation/${userHostConversations.at(-1)?.id}`
                                      : "/host-conversation/null"
                            }
                            onClick={() => {
                                closeNavbarMenu();
                            }}
                            style={{ padding: "12px 20px" }}
                        >
                            <NavbarMenuItemMainIcon width="24" height="24" viewBox="0 0 24 24" fill="none" aria-label="Messages icon">
                                <mask id="path-1-inside-1_54966_12034" fill="white">
                                    <path d="M0 6.24062C0 4.0562 1.49012e-07 2.96398 0.435974 2.12965C0.819467 1.39574 1.43139 0.79906 2.18404 0.425117C3.03968 1.49012e-07 4.15979 0 6.4 0H17.6C19.8403 0 20.9603 1.49012e-07 21.816 0.425117C22.5687 0.79906 23.1805 1.39574 23.564 2.12965C24 2.96398 24 4.0562 24 6.24062V13.2613C24 15.4458 24 16.5379 23.564 17.3723C23.1805 18.1063 22.5687 18.7029 21.816 19.0768C20.9603 19.5019 19.8403 19.5019 17.6 19.5019H14.2449C13.4129 19.5019 12.9969 19.5019 12.5989 19.5816C12.2459 19.6522 11.9043 19.7691 11.5833 19.929C11.2216 20.1092 10.8967 20.3626 10.2469 20.8694L7.06635 23.3506C6.51156 23.7834 6.23417 23.9997 6.00072 24C5.79769 24.0003 5.60563 23.9102 5.47897 23.7554C5.33333 23.5776 5.33333 23.2312 5.33333 22.5384V19.5019C4.09337 19.5019 3.47339 19.5019 2.96472 19.3691C1.58436 19.0084 0.50616 17.957 0.136293 16.6111C8.9407e-08 16.1151 0 15.5105 0 14.3014V6.24062Z" />
                                </mask>
                                <path
                                    d="M0 6.24062C0 4.0562 1.49012e-07 2.96398 0.435974 2.12965C0.819467 1.39574 1.43139 0.79906 2.18404 0.425117C3.03968 1.49012e-07 4.15979 0 6.4 0H17.6C19.8403 0 20.9603 1.49012e-07 21.816 0.425117C22.5687 0.79906 23.1805 1.39574 23.564 2.12965C24 2.96398 24 4.0562 24 6.24062V13.2613C24 15.4458 24 16.5379 23.564 17.3723C23.1805 18.1063 22.5687 18.7029 21.816 19.0768C20.9603 19.5019 19.8403 19.5019 17.6 19.5019H14.2449C13.4129 19.5019 12.9969 19.5019 12.5989 19.5816C12.2459 19.6522 11.9043 19.7691 11.5833 19.929C11.2216 20.1092 10.8967 20.3626 10.2469 20.8694L7.06635 23.3506C6.51156 23.7834 6.23417 23.9997 6.00072 24C5.79769 24.0003 5.60563 23.9102 5.47897 23.7554C5.33333 23.5776 5.33333 23.2312 5.33333 22.5384V19.5019C4.09337 19.5019 3.47339 19.5019 2.96472 19.3691C1.58436 19.0084 0.50616 17.957 0.136293 16.6111C8.9407e-08 16.1151 0 15.5105 0 14.3014V6.24062Z"
                                    stroke={ColorTheme.Black.color}
                                    strokeWidth="3.2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    mask="url(#path-1-inside-1_54966_12034)"
                                />
                            </NavbarMenuItemMainIcon>
                            Inbox
                        </NavbarMenuItem>
                        <NavbarMenuItem
                            to={`/your-calendar`}
                            onClick={() => {
                                closeNavbarMenu();
                            }}
                            style={{ padding: "12px 20px" }}
                        >
                            <NavbarMenuItemMainIcon viewBox="-1 -1 32 32" height="32" width="32" stroke={ColorTheme.Black.color} fill="none" aria-label="calendar">
                                <g id="calendar-mark--calendar-date-day-month-mark">
                                    <path
                                        id="Vector"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M3.214 4.286a2.143 2.143 0 0 0 -2.143 2.143v20.357a2.143 2.143 0 0 0 2.143 2.143h23.571a2.143 2.143 0 0 0 2.143 -2.143V6.429a2.143 2.143 0 0 0 -2.143 -2.143h-4.286"
                                        strokeWidth="2"
                                    />
                                    <path id="Vector_2" strokeLinecap="round" strokeLinejoin="round" d="M7.5 1.071v6.429" strokeWidth="2" />
                                    <path id="Vector_3" strokeLinecap="round" strokeLinejoin="round" d="M22.5 1.071v6.429" strokeWidth="2" />
                                    <path id="Vector_4" strokeLinecap="round" strokeLinejoin="round" d="M7.5 4.286h10.714" strokeWidth="2" />
                                    <g id="Group 2567">
                                        <path id="Vector_5" strokeLinecap="round" strokeLinejoin="round" d="M7.5 14.464a0.536 0.536 0 0 1 0 -1.071" strokeWidth="2" />
                                        <path id="Vector_6" strokeLinecap="round" strokeLinejoin="round" d="M7.5 14.464a0.536 0.536 0 0 0 0 -1.071" strokeWidth="2" />
                                    </g>
                                    <g id="Group 2617">
                                        <path id="Vector_7" strokeLinecap="round" strokeLinejoin="round" d="M15 14.464a0.536 0.536 0 0 1 0 -1.071" strokeWidth="2" />
                                        <path id="Vector_8" strokeLinecap="round" strokeLinejoin="round" d="M15 14.464a0.536 0.536 0 0 0 0 -1.071" strokeWidth="2" />
                                    </g>
                                    <g id="Group 2619">
                                        <path id="Vector_9" strokeLinecap="round" strokeLinejoin="round" d="M7.5 21.964a0.536 0.536 0 1 1 0 -1.071" strokeWidth="2" />
                                        <path id="Vector_10" strokeLinecap="round" strokeLinejoin="round" d="M7.5 21.964a0.536 0.536 0 1 0 0 -1.071" strokeWidth="2" />
                                    </g>
                                    <g id="Group 2620">
                                        <path id="Vector_11" strokeLinecap="round" strokeLinejoin="round" d="M15 21.964a0.536 0.536 0 1 1 0 -1.071" strokeWidth="2" />
                                        <path id="Vector_12" strokeLinecap="round" strokeLinejoin="round" d="M15 21.964a0.536 0.536 0 1 0 0 -1.071" strokeWidth="2" />
                                    </g>
                                    <g id="Group 2618">
                                        <path id="Vector_13" strokeLinecap="round" strokeLinejoin="round" d="M22.5 14.464a0.536 0.536 0 1 1 0 -1.071" strokeWidth="2" />
                                        <path id="Vector_14" strokeLinecap="round" strokeLinejoin="round" d="M22.5 14.464a0.536 0.536 0 1 0 0 -1.071" strokeWidth="2" />
                                    </g>
                                </g>
                            </NavbarMenuItemMainIcon>
                            Calendar
                        </NavbarMenuItem>
                        <NavbarMenuItem
                            to={`/your-listings/${userHostModel?.id}`}
                            onClick={() => {
                                closeNavbarMenu();
                            }}
                            style={{ padding: "12px 20px" }}
                        >
                            <NavbarMenuItemMainIcon viewBox="0 0 400.000000 400.000000" fill={ColorTheme.Black.color} aria-label="Listings">
                                <g transform="translate(0.000000,400.000000) scale(0.100000,-0.100000)">
                                    <path d="M105 3871 c-49 -12 -71 -28 -89 -65 -15 -31 -16 -170 -16 -1556 0 -1679 -4 -1565 63 -1605 31 -20 62 -20 1687 -20 1625 0 1656 0 1687 20 67 40 63 -74 63 1605 0 1686 5 1570 -64 1608 -29 16 -146 17 -1666 19 -899 0 -1648 -2 -1665 -6z m3145 -1621 l0 -1370 -1500 0 -1500 0 0 1370 0 1370 1500 0 1500 0 0 -1370z" />
                                    <path d="M1639 3068 c-228 -184 -736 -614 -746 -632 -7 -11 -12 -38 -13 -59 -1 -112 104 -160 203 -93 l35 24 4 -367 3 -367 30 -59 c42 -83 101 -123 197 -137 42 -5 233 -8 453 -6 l380 3 54 30 c29 16 64 43 77 60 53 70 54 76 54 475 0 203 2 370 5 370 2 0 21 -11 41 -25 51 -34 94 -41 138 -20 69 33 89 126 40 184 -23 27 -671 573 -765 644 -27 20 -47 27 -80 27 -39 0 -53 -7 -110 -52z m301 -388 l180 -151 0 -449 0 -450 -370 0 -370 0 0 450 0 449 178 150 c97 82 183 150 190 150 7 1 94 -67 192 -149z" />
                                    <path d="M3823 3145 c-18 -8 -42 -29 -53 -47 -20 -33 -20 -47 -20 -1326 0 -1129 -2 -1298 -15 -1326 -31 -66 81 -61 -1475 -66 -1309 -5 -1417 -6 -1446 -22 -33 -18 -64 -71 -64 -108 0 -37 29 -85 63 -105 31 -20 63 -20 1397 -23 857 -2 1396 1 1447 7 124 15 220 72 281 168 65 101 62 35 62 1468 0 1286 0 1300 -20 1333 -20 33 -72 62 -107 62 -10 -1 -33 -7 -50 -15z" />
                                </g>
                            </NavbarMenuItemMainIcon>
                            Listings
                        </NavbarMenuItem>
                        <NavbarMenuItem
                            to={`/payouts`}
                            onClick={() => {
                                closeNavbarMenu();
                            }}
                            style={{ padding: "12px 20px" }}
                        >
                            <NavbarMenuItemMainIcon viewBox="0 0 24 24" stroke={ColorTheme.Black.color} fill="none" aria-label="Messages icon">
                                <path
                                    d="M8 16V11M12 16V8M16 16V14M18 4H6C5.46957 4 4.96086 4.21071 4.58579 4.58579C4.21071 4.96086 4 5.46957 4 6V18C4 18.5304 4.21071 19.0391 4.58579 19.4142C4.96086 19.7893 5.46957 20 6 20H18C18.5304 20 19.0391 19.7893 19.4142 19.4142C19.7893 19.0391 20 18.5304 20 18V6C20 5.46957 19.7893 4.96086 19.4142 4.58579C19.0391 4.21071 18.5304 4 18 4Z"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </NavbarMenuItemMainIcon>
                            Payouts
                        </NavbarMenuItem>
                        <NavbarMenuDivider />
                    </>
                )}
                <NavbarMenuItem
                    to="/settings"
                    onClick={() => {
                        closeNavbarMenu();
                        setColumnNavText?.(false);
                    }}
                >
                    Settings
                </NavbarMenuItem>
                <NavbarMenuItemMainItemOutsideLink
                    onClick={async () => {
                        const searchFiltersCopy = JSON.parse(JSON.stringify(searchFilters));
                        searchFiltersCopy.category = "all";
                        setSearchFilters(searchFiltersCopy);
                        setIsUserChangePrice(false);
                        closeNavbarMenu();
                        setColumnNavText?.(false);
                    }}
                    target={window.innerWidth > 768 ? "_blank" : ""}
                    href="https://help.anystay.com.au"
                    style={{ gap: "12px" }}
                >
                    Help center
                </NavbarMenuItemMainItemOutsideLink>
                <NavbarMenuItem
                    to={`/create-listing/${userHostModel?.id}`}
                    onClick={() => {
                        closeNavbarMenu();
                        setColumnNavText?.(false);
                    }}
                >
                    Create a listing
                </NavbarMenuItem>
                <NavbarMenuDivider style={{ marginBottom: "8px" }} />

                <NavbarMenuTextItemWrapper
                    to="/host-referral-page"
                    onClick={() => {
                        closeNavbarMenu();
                        setColumnNavText?.(false);
                    }}
                >
                    <NavbarMenuTextWrapper>
                        <NavbarMenuText1>Refer a friend</NavbarMenuText1>
                        <NavbarMenuText2>Invite friends and earn</NavbarMenuText2>
                    </NavbarMenuTextWrapper>
                    <NavbarMenuRedDot />
                </NavbarMenuTextItemWrapper>
                <NavbarMenuItem
                    to="/"
                    onClick={async () => {
                        await logoutUser();
                        closeNavbarMenu();
                        setColumnNavText?.(false);
                        navigate("/");
                    }}
                    style={{ padding: "13px 20px" }}
                >
                    Log out
                </NavbarMenuItem>
            </NavbarMenuWrapper>
        );
    } else {
        return <></>;
    }
}
