import { DatePicker } from "anystay-ui";
import { forwardRef } from "react";

interface DatePickerRef extends HTMLInputElement {
    forceClearSelect: () => void;
}
interface MobileNavbarSearchMenuCalendarProps {
    date: (string | Date | null)[];
    setStartDate: React.Dispatch<React.SetStateAction<string | Date | null>>;
    setEndDate: React.Dispatch<React.SetStateAction<string | Date | null>>;
    setDate: React.Dispatch<React.SetStateAction<(string | Date | null)[]>>;
}

const MobileNavbarSearchMenuCalendar = forwardRef(({ date, setStartDate, setEndDate, setDate }: MobileNavbarSearchMenuCalendarProps, ref: React.Ref<DatePickerRef>) => {
    let dateRangeForMobileDatePicker = [];
    if ((date as Array<Date | null | string>)[0]) {
        const startDate = (date as Array<Date | null | string>)[0];
        if (startDate) {
            dateRangeForMobileDatePicker.push(new Date(startDate).toISOString().slice(0, 10));
        }
    }
    if ((date as Array<Date | null | string>)[1]) {
        const endDate = (date as Array<Date | null | string>)[1];
        if (endDate) {
            dateRangeForMobileDatePicker.push(new Date(endDate).toISOString().slice(0, 10));
        }
    }
    return (
        <DatePicker
            ref={ref}
            subtractMonthNumber={1}
            value={dateRangeForMobileDatePicker}
            totalMonthNumber={20}
            onSelect={(prop) => {
                if (prop.startDate && prop.endDate) {
                    setDate([new Date(prop.startDate), new Date(prop.endDate)]);
                    setStartDate(new Date(prop.startDate));
                    setEndDate(new Date(prop.endDate));
                } else {
                    setDate([new Date(prop.startDate)]);
                }
            }}
        />
    );
});

export default MobileNavbarSearchMenuCalendar;
