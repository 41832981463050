import { MobileNavbarWrapper } from "./mobileNavbarStyling";
// Import search components
import MobileSearchMenu from "./search/mobileSearchMenu";
import MobileNavbarMenuItems from "./mobileNavbarMenuItems";
import { mobileSearchMenuActivatedSelector } from "../../../state/selectors/navbarToggle";
import { useRecoilState, useRecoilValue } from "recoil";
import { useLocation } from "react-router-dom";
import useScrollDirection from "../../../hook/useScrollDirection";
import { hideNavbarState } from "../../../state/atoms/hideNavbarState";

export default function MobileNavbar() {
    const [mobileSearchMenuActivated, setMobileSearchMenuActivated] = useRecoilState(mobileSearchMenuActivatedSelector);
    const location = useLocation();
    const [scrollDirection, isHitBottom] = useScrollDirection(window);
    const isHidden = useRecoilValue(hideNavbarState);

    const hideNavbar = () => {
        if (scrollDirection === "down" && !isHitBottom) {
            return true;
        }
        return false;
    };

    const notHideMobileNavbar = (pathname: string) => {
        const notHideOnPaths = ["/all-bookings", "/dashboard", "/your-host-inbox", "/your-inbox", "/your-calendar", "/payouts", "/saved-listings", "/settings", "/your-listings", "/your-stays"];
        return notHideOnPaths.some((path) => pathname === path || pathname.startsWith(`${path}/`));
    };
    const notHide = notHideMobileNavbar(location.pathname);

    return (
        <MobileNavbarWrapper
            hide={!mobileSearchMenuActivated && (location.pathname === "/" ? hideNavbar() : location.pathname === "/search" ? isHidden : !notHide)}
            mobileSearchMenuActivated={mobileSearchMenuActivated}
        >
            <MobileNavbarMenuItems />
            {mobileSearchMenuActivated && <MobileSearchMenu setMobileSearchMenuActivated={setMobileSearchMenuActivated} />}
        </MobileNavbarWrapper>
    );
}
