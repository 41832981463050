import { useState, useRef } from "react";
import {
    MobileNavbarSearchMenuContent,
    MobileNavbarInput,
    MobileNavbarAutocompleteInputWrapper,
    MobileNavbarCalendarWrapper,
    MobileNavbarGuestsWrapper,
    SearchBarClearButton,
    MobileNavbarDateSearchTitleText,
    MobileNavbarSearchMenuWhenWhereWrapper,
    MobileNavbarSearchMenuWhenWhereContent,
    MobileNavbarSearchMenuWhenWhereSubtitle,
    SearchBarClearButtonIcon,
    MobileNavbarSerachWrapper,
    MobileNavbarSearchContentWrapper,
    MobileNavbarSearchTitle,
    MobileNavbarSearchContent,
    MobileNavbarSearchContentImg,
    MobileNavbarSearchContentText,
    MobileNavbarSearchContentImgContainer,
} from "../mobileNavbarStyling";
import MobileNavbarSearchMenuCalendar from "./mobileSearchMenuDatePicker";
import MobileNavbarGuestsInput from "./mobileSearchMenuGuestPicker";
// import MobileSearchPlacePicker from "./mobileSearchPlacePicker";
import { searchQuerySelector, searchFiltersSelector } from "../../../../state/selectors/searchQuery";
import { SetterOrUpdater, useRecoilState } from "recoil";
import { useNavigate } from "react-router-dom";
import { ColorTheme, SpacingTheme } from "../../../globaStyledComponents/globalStyledComponentsStyling";
import { calculateNumberOfFilterSelected } from "../../../categoryBar/filterBtn/calculateNumberOfFilterSelected";
import { isUserChangePriceSelector } from "../../../../state/selectors/isUserChangePrice";
import GlobalPopupContainerFullScreen from "../../../globalPopup/GlobalPopupContainerFullScreen";

interface MobileNavbarSearchMenuProps {
    setMobileSearchMenuActivated: SetterOrUpdater<boolean>;
}
interface DatePickerRef extends HTMLInputElement {
    forceClearSelect: () => void;
}

function MobileNavbarSearchMenu({ setMobileSearchMenuActivated }: MobileNavbarSearchMenuProps) {
    const [searchQuery, setSearchQuery] = useRecoilState(searchQuerySelector);
    let [input, setInput] = useState(searchQuery.title ? searchQuery.title : "");
    let navigate = useNavigate();
    const [searchMenuIndex, setSearchMenuIndex] = useState(0);
    const [searchFilters, setSearchFilters] = useRecoilState(searchFiltersSelector);
    const [isUserChangePrice, setIsUserChangePrice] = useRecoilState(isUserChangePriceSelector);
    const [startDate, setStartDate] = useState(searchQuery.startDate);
    const [endDate, setEndDate] = useState(searchQuery.endDate);
    const [date, setDate] = useState([startDate, endDate]);
    const [guests, setGuests] = useState({
        adults: searchQuery.searchFilters.adults,
        children: searchQuery.searchFilters.children,
        pets: searchQuery.searchFilters.pets,
        infants: searchQuery.searchFilters.infants,
    });
    const [title, setTitle] = useState(searchQuery.title);
    const [placeId, setPlaceId] = useState(searchQuery.placeId);
    const [lng, setLng] = useState(searchQuery.lng);
    const [lat, setLat] = useState(searchQuery.lat);

    const [inputFocused, setInputFocused] = useState(false);
    const datePickerRef = useRef<DatePickerRef>(null);
    const searchesExamples = ["Apartments with views in Melbourne", "Beachfront homes in Cairns", "Pet friendly stays in Noosa", "Last minute stays nearby"];

    let submitSearch = async () => {
        let searchQueryCopy = JSON.parse(JSON.stringify(searchQuery));
        searchQueryCopy.lat = lat;
        searchQueryCopy.lng = lng;
        searchQueryCopy.startDate = startDate;
        searchQueryCopy.endDate = endDate;
        searchQueryCopy.title = input ? input : "";
        searchQueryCopy.text = input ? input : "";
        searchQueryCopy.placeId = placeId;
        searchQueryCopy.searchFilters.guests = guests.adults + guests.children;
        searchQueryCopy.searchFilters.adults = guests.adults;
        searchQueryCopy.searchFilters.children = guests.children;
        searchQueryCopy.searchFilters.pets = guests.pets;
        searchQueryCopy.searchFilters.infants = guests.infants;
        // setSearchInput(input ? input : "");
        setSearchQuery(searchQueryCopy);
        setMobileSearchMenuActivated(false);
        const searchFiltersCopy = JSON.parse(JSON.stringify(searchQueryCopy.searchFilters));
        searchFiltersCopy.category = "all";
        setSearchFilters(searchFiltersCopy);
        const text = searchQueryCopy.text ? `text=${searchQueryCopy.text}` : "";
        const placeTitle = searchQueryCopy.title ? searchQueryCopy.title : "Australia";
        const arrivalDate = searchQueryCopy.startDate
            ? `${String((searchQueryCopy.startDate as Date).getDate()).padStart(2, "0")}-${String((searchQueryCopy.startDate as Date).getMonth() + 1).padStart(2, "0")}-${(searchQueryCopy.startDate as Date).getFullYear()}`
            : null;
        const departureDate = searchQueryCopy.endDate
            ? `${String((searchQueryCopy.endDate as Date).getDate()).padStart(2, "0")}-${String((searchQueryCopy.endDate as Date).getMonth() + 1).padStart(2, "0")}-${(searchQueryCopy.endDate as Date).getFullYear()}`
            : null;
        const locationString = placeTitle !== "Australia" ? `location=${placeTitle}` : "";
        const locationIdString = searchQueryCopy.placeId !== "ChIJ38WHZwf9KysRUhNblaFnglM" ? `locationId=${searchQueryCopy.placeId}` : "";
        const arrivalDateString = arrivalDate ? `arrivalDate=${arrivalDate}` : "";
        const departureDateString = departureDate ? `departureDate=${departureDate}` : "";
        const latString = searchQueryCopy.lat ? `lat=${searchQueryCopy.lat}` : "";
        const lngString = searchQueryCopy.lng ? `lng=${searchQueryCopy.lng}` : "";

        const searchFiltersString =
            calculateNumberOfFilterSelected(searchQueryCopy.searchFilters, isUserChangePrice) > 0 ||
            searchQueryCopy.searchFilters.adults ||
            searchQueryCopy.searchFilters.children ||
            searchQueryCopy.searchFilters.infants ||
            searchQueryCopy.searchFilters.pets
                ? `searchFilters=${JSON.stringify(searchQueryCopy.searchFilters)}`
                : "";

        const searchParamsStrings = [text, locationString, locationIdString, arrivalDateString, departureDateString, latString, lngString, searchFiltersString]
            .filter((param) => param !== "")
            .join("&");

        navigate(`/search${searchParamsStrings ? `?${searchParamsStrings}` : ""}`);
    };

    function formatShortDate(dateString: Date | string) {
        const date = new Date(dateString);
        const monthAbbreviation = date.toLocaleString("default", { month: "short" });
        return `${date.getDate()} ${monthAbbreviation}`;
    }
    if (searchMenuIndex === 0) {
        return (
            <GlobalPopupContainerFullScreen
                noBackBtn
                noPadding
                cancelBtnOnClick={() => setMobileSearchMenuActivated(false)}
                continueBtnOnClick={() => setSearchMenuIndex(1)}
                continueBtnImg="https://d292awxalydr86.cloudfront.net/Universal+icons/search-white.svg"
                continueBtnText="Search"
                wrapperStyle={{ background: "rgba(255, 255, 255, 0.9)", backdropFilter: "blur(50px)", borderRadius: "32px 32px 0 0" }}
                btnStyle={{ background: "none" }}
            >
                <MobileNavbarSearchMenuContent>
                    <MobileNavbarAutocompleteInputWrapper>
                        <MobileNavbarInput
                            // apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                setInput(e.target.value);
                                setInputFocused(true);
                                if (e.target.value === "") {
                                    setInputFocused(false);
                                }
                            }}
                            onBlur={() => {
                                setInputFocused(false);
                            }}
                            value={input}
                            style={{ color: input ? ColorTheme.Black.color : ColorTheme.Grey1.color, marginLeft: "24px" }}
                            // onPlaceSelected={(place: google.maps.places.PlaceResult) => {
                            //     if (place && place.address_components && place.address_components[0]) {
                            //         const input = (place.address_components as google.maps.GeocoderAddressComponent[])[0].types.includes("postal_code")
                            //             ? (place.address_components as google.maps.GeocoderAddressComponent[])[1].long_name
                            //             : (place.address_components as google.maps.GeocoderAddressComponent[])[0].long_name;
                            //         setInput(input);
                            //         setTitle(input);
                            //     }
                            //     setPlaceId(place.place_id as string);
                            //     if (place.geometry && place.geometry.location) {
                            //         setLat(place.geometry.location.lat());
                            //         setLng(place.geometry.location.lng());
                            //     }
                            //     setInputFocused(false);
                            //     setSearchMenuIndex(1);
                            // }}
                            placeholder={"Search or describe..."}
                            // options={{
                            //     types: ["(regions)"],
                            //     componentRestrictions: { country: ["au", "nz"] },
                            // }}
                            // noSuggestions={true}
                        />
                        {input && (
                            <SearchBarClearButton
                                type="button"
                                onTouchEnd={(event) => {
                                    event.preventDefault();
                                    event.stopPropagation();
                                    setInput("");
                                    setTitle(null);
                                    setLng(null);
                                    setLat(null);
                                    setPlaceId("ChIJ38WHZwf9KysRUhNblaFnglM");
                                }}
                                onClick={() => {
                                    setInput("");
                                    setTitle(null);
                                    setLng(null);
                                    setLat(null);
                                    setPlaceId("ChIJ38WHZwf9KysRUhNblaFnglM");
                                }}
                            >
                                <SearchBarClearButtonIcon src={"https://d292awxalydr86.cloudfront.net/Universal+icons/Cross.svg"} alt="Clear address" />
                            </SearchBarClearButton>
                        )}
                    </MobileNavbarAutocompleteInputWrapper>

                    {/* {!inputFocused && ( */}
                        <>
                            <MobileNavbarSearchMenuWhenWhereWrapper>
                                <MobileNavbarSearchMenuWhenWhereContent
                                    onTouchEnd={(event) => {
                                        setSearchMenuIndex(1);
                                    }}
                                    onClick={() => {
                                        setSearchMenuIndex(1);
                                    }}
                                >
                                    <MobileNavbarSearchMenuWhenWhereSubtitle>
                                        {startDate && endDate ? `${formatShortDate(startDate)} - ${formatShortDate(endDate)}` : "Dates"}
                                    </MobileNavbarSearchMenuWhenWhereSubtitle>
                                </MobileNavbarSearchMenuWhenWhereContent>

                                <MobileNavbarSearchMenuWhenWhereContent
                                    onTouchEnd={(event) => {
                                        event.preventDefault();
                                        event.stopPropagation();
                                        setSearchMenuIndex(2);
                                    }}
                                    onClick={() => {
                                        setSearchMenuIndex(2);
                                    }}
                                >
                                    <MobileNavbarSearchMenuWhenWhereSubtitle>
                                        {guests.adults === 0 && guests.children === 0 ? "Guests" : `${guests.adults + guests.children} guests`}
                                    </MobileNavbarSearchMenuWhenWhereSubtitle>
                                </MobileNavbarSearchMenuWhenWhereContent>
                            </MobileNavbarSearchMenuWhenWhereWrapper>

                            <MobileNavbarSerachWrapper>
                                <MobileNavbarSearchTitle>Popular searches</MobileNavbarSearchTitle>

                                <MobileNavbarSearchContentWrapper>
                                    {searchesExamples &&
                                        searchesExamples.map((searchesExample, index) => {
                                            return (
                                                <MobileNavbarSearchContent
                                                    onClick={() => {
                                                        setInput(searchesExample);
                                                        setSearchMenuIndex(1);
                                                    }}
                                                >
                                                    <MobileNavbarSearchContentImgContainer>
                                                        <MobileNavbarSearchContentImg src="https://d292awxalydr86.cloudfront.net/Universal+icons/Search.svg" alt="search icon" />
                                                    </MobileNavbarSearchContentImgContainer>
                                                    <MobileNavbarSearchContentText>{searchesExample}</MobileNavbarSearchContentText>
                                                </MobileNavbarSearchContent>
                                            );
                                        })}
                                </MobileNavbarSearchContentWrapper>
                            </MobileNavbarSerachWrapper>
                        </>
                    {/* )} */}
                </MobileNavbarSearchMenuContent>
            </GlobalPopupContainerFullScreen>
        );
    } else if (searchMenuIndex === 1) {
        return (
            <GlobalPopupContainerFullScreen
                noBackBtn
                noPadding
                cancelBtnOnClick={() => setSearchMenuIndex(0)}
                continueBtnOnClick={() => setSearchMenuIndex(2)}
                continueBtnImg="https://d292awxalydr86.cloudfront.net/Universal+icons/search-white.svg"
                continueBtnText="Search"
                wrapperStyle={{ background: "rgba(255, 255, 255, 0.9)", backdropFilter: "blur(50px)", borderRadius: "32px 32px 0 0" }}
                btnStyle={{ background: "none" }}
            >
                <MobileNavbarSearchMenuContent>
                    <MobileNavbarDateSearchTitleText>Add your dates</MobileNavbarDateSearchTitleText>
                    <MobileNavbarCalendarWrapper>
                        <MobileNavbarSearchMenuCalendar ref={datePickerRef} date={date} setStartDate={setStartDate} setEndDate={setEndDate} setDate={setDate} />
                    </MobileNavbarCalendarWrapper>
                </MobileNavbarSearchMenuContent>
            </GlobalPopupContainerFullScreen>
        );
    } else if (searchMenuIndex === 2) {
        return (
            <GlobalPopupContainerFullScreen
                noBackBtn
                noPadding
                cancelBtnOnClick={() => setSearchMenuIndex(0)}
                continueBtnOnClick={submitSearch}
                continueBtnImg="https://d292awxalydr86.cloudfront.net/Universal+icons/search-white.svg"
                continueBtnText="Search"
                wrapperStyle={{ background: "rgba(255, 255, 255, 0.9)", backdropFilter: "blur(50px)", borderRadius: "32px 32px 0 0" }}
                btnStyle={{ background: "none" }}
            >
                <MobileNavbarSearchMenuContent>
                    <MobileNavbarDateSearchTitleText style={{ marginBottom: "0" }}>Who's coming?</MobileNavbarDateSearchTitleText>
                    <MobileNavbarGuestsWrapper>
                        <MobileNavbarGuestsInput guests={guests} setGuests={setGuests} />
                    </MobileNavbarGuestsWrapper>
                </MobileNavbarSearchMenuContent>
            </GlobalPopupContainerFullScreen>
        );
    } else {
        return <></>;
    }
}

export default MobileNavbarSearchMenu;
