import { MouseEventHandler, ReactNode } from "react";
import AnimationWrapperForPopup from "../animationWrapper/AnimationWrapperForPopup";
import BackBtn from "../btn/backBtn/backBtn";
import { FullScreenContinueBtnImg, FullScreenPopupBtnWrapper, FullScreenPopupCancelBtn, FullScreenPopupContentWrapper, FullScreenPopupWrapper } from "./GlobalPopupContainerStyle";
import ContinueBtn from "../btn/continueBtn/continueBtn";

interface GlobalPopupContainerFullScreenProps {
    wrapperStyle?: React.CSSProperties;
    btnStyle?: React.CSSProperties;
    noBackBtn?: boolean;
    backBtnOnClick?: MouseEventHandler<HTMLButtonElement>;
    backBtnType?: "Arrow" | "Close";
    backBtnDisabled?: boolean;
    mobileHeight?: string;
    children: ReactNode;
    noPadding?: boolean;
    noContentWrapper?: boolean;
    cancelBtnOnClick?: MouseEventHandler<HTMLButtonElement>;
    cancelBtnDisabled?: boolean;
    cancelBtnText?: string;
    continueBtnOnClick?: MouseEventHandler<HTMLButtonElement>;
    continueBtnImg?: string;
    continueBtnText?: string;
    continueBtnBgColor?: string;
    continueBtnLoading?: boolean;
    continueBtnDisabled?: boolean;
}

const GlobalPopupContainerFullScreen = ({ ...props }: GlobalPopupContainerFullScreenProps) => {
    return (
        <AnimationWrapperForPopup>
            <FullScreenPopupWrapper mobileHeight={props.mobileHeight} style={props.wrapperStyle}>
                {!props.noBackBtn && (
                    <BackBtn
                        onClick={props.backBtnOnClick}
                        type={props.backBtnType ? props.backBtnType : "Close"}
                        desktopTop={32}
                        desktopLeft={32}
                        mobileLeft={24}
                        mobileTop={24}
                        disabled={props.backBtnDisabled ? props.backBtnDisabled : false}
                    />
                )}
                {props.noContentWrapper ? <>{props.children}</> : <FullScreenPopupContentWrapper noPadding={props.noPadding}>{props.children}</FullScreenPopupContentWrapper>}
                {!props.noContentWrapper && (
                    <FullScreenPopupBtnWrapper style={props.btnStyle}>
                        <FullScreenPopupCancelBtn onClick={props.cancelBtnOnClick} disabled={props.cancelBtnDisabled ? props.cancelBtnDisabled : false}>
                            {props.cancelBtnText ? props.cancelBtnText : "Cancel"}
                        </FullScreenPopupCancelBtn>
                        <ContinueBtn
                            background={props.continueBtnBgColor ? props.continueBtnBgColor : "black"}
                            style={{ width: "unset" }}
                            onClick={props.continueBtnOnClick}
                            isLoading={props.continueBtnLoading ? props.continueBtnLoading : false}
                            disabled={props.continueBtnDisabled ? props.continueBtnDisabled : false}
                        >
                            {props.continueBtnImg && <FullScreenContinueBtnImg src={`${props.continueBtnImg}`} />}
                            {props.continueBtnText ? props.continueBtnText : "Save"}
                        </ContinueBtn>
                    </FullScreenPopupBtnWrapper>
                )}
            </FullScreenPopupWrapper>
        </AnimationWrapperForPopup>
    );
};

export default GlobalPopupContainerFullScreen;
