import { DefaultValue, selector } from "recoil";
import {
    mobileSearchMenuActivatedState,
    signInMenuActivatedState,
    navbarSearchMenuActivatedState,
    navbarMenuActivatedState,
    navbarSearchbarPopTypes,
    navbarSearchFilterState,
    navbarSearchFilterNumberBubble,
} from "../atoms/navbarToggle";

export const mobileSearchMenuActivatedSelector = selector<boolean>({
    key: "mobileSearchMenuActivatedSelector",
    get: ({ get }) => get(mobileSearchMenuActivatedState),
    set: ({ set }, newValue: boolean | DefaultValue) => {
        return set(mobileSearchMenuActivatedState, newValue);
    },
});

export const navbarSearchMenuActivatedSelector = selector<boolean>({
    key: "navbarSearchMenuActivatedSelector",
    get: ({ get }) => get(navbarSearchMenuActivatedState),
    set: ({ set }, newValue: boolean | DefaultValue) => {
        return set(navbarSearchMenuActivatedState, newValue);
    },
});

export const signInMenuActivatedSelector = selector<boolean>({
    key: "signInMenuActivatedSelector",
    get: ({ get }) => get(signInMenuActivatedState),
    set: ({ set }, newValue: boolean | DefaultValue) => {
        return set(signInMenuActivatedState, newValue);
    },
});

export const navbarMenuSelector = selector<boolean>({
    key: "navbarMenuSelector",
    get: ({ get }) => get(navbarMenuActivatedState),
    set: ({ set }, newValue: boolean | DefaultValue) => {
        return set(navbarMenuActivatedState, newValue);
    },
});

export const navbarSearchbarPopType = selector<string>({
    key: "directToAfterLoginSelector",
    get: ({ get }) => get(navbarSearchbarPopTypes),
    set: ({ set }, newValue: string | DefaultValue) => {
        if (!(newValue instanceof DefaultValue)) {
            return set(navbarSearchbarPopTypes, newValue);
        }
    },
});

export const navbarSearchFilter = selector<boolean>({
    key: "navbarSearchFilter",
    get: ({ get }) => get(navbarSearchFilterState),
    set: ({ set }, newValue: boolean | DefaultValue) => {
        return set(navbarSearchFilterState, newValue);
    },
});

export const navbarSearchFilterNumber = selector<number>({
    key: "navbarSearchFilterNumber",
    get: ({ get }) => get(navbarSearchFilterNumberBubble),
    set: ({ set }, newValue: number | DefaultValue) => {
        return set(navbarSearchFilterNumberBubble, newValue);
    },
});
