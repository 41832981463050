import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    MobileNavbarMenuWrapper,
    MobileNavbarMenuItem,
    MobileNavbarMenuItemText,
    MobileNavbarProfilePicture,
    MobileNavbarMenuWrapperNotLoggedIn,
    MobileNavbarMenuWrapperNotLoggedInIcon,
    MobileNavbarMenuItemLogIn,
    MobileNavbarMenuImage,
    MobileNavbarProfilePictureWrapper,
    MobileNavbarMenuSVG,
} from "./mobileNavbarStyling";
import { userModelSelector } from "../../../state/selectors/userModel";
import { userHostModelSelector } from "../../../state/selectors/userHostModel";
import { signInMenuActivatedSelector } from "../../../state/selectors/navbarToggle";
import { useRecoilState } from "recoil";
import { useEffect } from "react";
import { ColorTheme, TextTheme } from "../../globaStyledComponents/globalStyledComponentsStyling";
import { searchFiltersSelector } from "../../../state/selectors/searchQuery";
import constructOptimisedProtectedImageUrl from "../../../utils/imageOptimisation/constructOptimisedProtectedImageUrl";

export default function MobileNavbarMenuItems() {
    const navigate = useNavigate();
    const [userModel, setUserModel] = useRecoilState(userModelSelector);
    const [userHostModel, setUserHostModel] = useRecoilState(userHostModelSelector);
    const [signInMenuActivated, setSignInMenuActivated] = useRecoilState(signInMenuActivatedSelector);
    const [profilePicture, setProfilePicture] = useState("https://d292awxalydr86.cloudfront.net/Settings+page/Profile+photo+placeholder.png");
    const [searchFilters, setSearchFilters] = useRecoilState(searchFiltersSelector);

    let currentIndex = 1;
    if (window.location.href.includes("/saved-listings")) {
        currentIndex = 2;
    } else if (window.location.href.includes("/your-stays")) {
        currentIndex = 3;
    } else if (window.location.href.includes("/your-inbox")) {
        currentIndex = 4;
    } else if (window.location.href.includes("/settings")) {
        currentIndex = 5;
    } else if (window.location.href.includes("/dashboard")) {
        currentIndex = 6;
    } else if (window.location.href.includes("/your-host-inbox")) {
        currentIndex = 7;
    } else if (window.location.href.includes("/your-calendar")) {
        currentIndex = 8;
    } else if (window.location.href.includes("/your-listings")) {
        currentIndex = 9;
    }

    useEffect(() => {
        if (userModel && userModel.profilePicture) {
            const profilePic = constructOptimisedProtectedImageUrl(userModel.profilePicture, userModel.identityID, 100);
            setProfilePicture(profilePic);
        }
    }, [userModel]);

    const handleMenuItemInteraction = () => {
        const searchFiltersCopy = JSON.parse(JSON.stringify(searchFilters));
        searchFiltersCopy.category = "all";
        setSearchFilters(searchFiltersCopy);
        navigate("/");
    };

    if (userModel) {
        if (userModel.guestMode) {
            return (
                <MobileNavbarMenuWrapper>
                    <MobileNavbarMenuItemNav url="/" text={"Home"} alt={"Search button"} currentIndex={currentIndex} color={currentIndex === 1 ? ColorTheme.Black.color : ColorTheme.Grey.color} />
                    <MobileNavbarMenuItemNav
                        url={`/saved-listings/${userModel.id}`}
                        text={"Wishlists"}
                        alt={"Wishlists button"}
                        currentIndex={currentIndex}
                        color={currentIndex === 2 ? ColorTheme.Black.color : ColorTheme.Grey.color}
                    />

                    <MobileNavbarMenuItemNav
                        url={`/your-stays/${userModel.id}`}
                        text={"Trips"}
                        alt={"Your stays button"}
                        currentIndex={currentIndex}
                        color={currentIndex === 3 ? ColorTheme.Black.color : ColorTheme.Grey.color}
                    />
                    <MobileNavbarMenuItemNav
                        url={`/your-inbox/${userModel.id}`}
                        text={"Messages"}
                        alt={"Inbox button"}
                        currentIndex={currentIndex}
                        color={currentIndex === 4 ? ColorTheme.Black.color : ColorTheme.Grey.color}
                    />
                    <MobileNavbarMenuItem
                        onTouchEnd={(event) => {
                            event.preventDefault();
                            event.stopPropagation();
                            navigate("/settings");
                        }}
                        onClick={() => {
                            navigate("/settings");
                        }}
                    >
                        <MobileNavbarProfilePictureWrapper selected={currentIndex === 5}>
                            <MobileNavbarProfilePicture src={profilePicture} alt="profile picture" />
                        </MobileNavbarProfilePictureWrapper>
                        <MobileNavbarMenuItemText color={currentIndex === 5 ? ColorTheme.Black.color : ColorTheme.Grey.color}>Profile</MobileNavbarMenuItemText>
                    </MobileNavbarMenuItem>
                </MobileNavbarMenuWrapper>
            );
        } else {
            return (
                <MobileNavbarMenuWrapper>
                    <MobileNavbarMenuItemNav
                        url="/dashboard"
                        text={"Home"}
                        alt={"Host Home button"}
                        currentIndex={currentIndex}
                        color={currentIndex === 6 ? ColorTheme.Black.color : ColorTheme.Grey.color}
                    />
                    <MobileNavbarMenuItemNav
                        url={`/your-host-inbox/${userHostModel ? userHostModel.id : ""}`}
                        text={"Inbox"}
                        alt={"Inbox button"}
                        currentIndex={currentIndex}
                        color={currentIndex === 7 ? ColorTheme.Black.color : ColorTheme.Grey.color}
                    />
                    <MobileNavbarMenuItemNav
                        url="/your-calendar"
                        text={"Calendar"}
                        alt={"Calendar button"}
                        currentIndex={currentIndex}
                        color={currentIndex === 8 ? ColorTheme.Black.color : ColorTheme.Grey.color}
                    />
                    <MobileNavbarMenuItemNav
                        url={`/your-listings/${userHostModel ? userHostModel.id : ""}`}
                        text={"Listings"}
                        alt={"Listings button"}
                        currentIndex={currentIndex}
                        color={currentIndex === 9 ? ColorTheme.Black.color : ColorTheme.Grey.color}
                    />
                    <MobileNavbarMenuItem
                        onTouchEnd={(event) => {
                            event.preventDefault();
                            event.stopPropagation();
                            navigate("/settings");
                        }}
                        onClick={() => {
                            navigate("/settings");
                        }}
                    >
                        <MobileNavbarProfilePictureWrapper selected={currentIndex === 5}>
                            <MobileNavbarProfilePicture src={profilePicture} alt="profile picture" />
                        </MobileNavbarProfilePictureWrapper>
                        <MobileNavbarMenuItemText color={currentIndex === 5 ? ColorTheme.Black.color : ColorTheme.Grey.color}>Profile</MobileNavbarMenuItemText>
                    </MobileNavbarMenuItem>
                </MobileNavbarMenuWrapper>
            );
        }
    } else {
        return (
            <MobileNavbarMenuWrapperNotLoggedIn>
                <MobileNavbarMenuItemNav url="/" text={"Home"} alt={"Search button"} currentIndex={currentIndex} color={currentIndex === 1 ? ColorTheme.Black.color : ColorTheme.Grey.color} />
                <MobileNavbarMenuItemNav
                    url={"/saved-listings"}
                    text={"Wishlists"}
                    alt={"Wishlists button"}
                    currentIndex={currentIndex}
                    color={currentIndex === 2 ? ColorTheme.Black.color : ColorTheme.Grey.color}
                />

                <MobileNavbarMenuItemNav
                    url={"/your-stays"}
                    text={"Trips"}
                    alt={"Your stays button"}
                    currentIndex={currentIndex}
                    color={currentIndex === 3 ? ColorTheme.Black.color : ColorTheme.Grey.color}
                />
                <MobileNavbarMenuItemNav
                    url={`/your-inbox`}
                    text={"Messages"}
                    alt={"Inbox button"}
                    currentIndex={currentIndex}
                    color={currentIndex === 4 ? ColorTheme.Black.color : ColorTheme.Grey.color}
                />
                <MobileNavbarMenuItemLogIn
                    stroke={ColorTheme.Black.color}
                    onTouchEnd={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        setSignInMenuActivated(true);
                    }}
                    onClick={() => {
                        setSignInMenuActivated(true);
                    }}
                >
                    <MobileNavbarMenuWrapperNotLoggedInIcon src={"https://d292awxalydr86.cloudfront.net/Settings+page/Profile+photo+placeholder.png"} alt="Log in icon" />
                    <MobileNavbarMenuItemText color={ColorTheme.Grey.color}>Log in</MobileNavbarMenuItemText>
                </MobileNavbarMenuItemLogIn>
            </MobileNavbarMenuWrapperNotLoggedIn>
        );
    }
}

interface MobileNavbarMenuItemProps {
    text: string;
    currentIndex: number;
    alt: string;
}

function MobileNavbarMenuItemSVG({ text, currentIndex, alt }: MobileNavbarMenuItemProps) {
    if (text === "Home" && alt === "Search button") {
        return (
            <MobileNavbarMenuSVG width="356" height="356" viewBox="0 0 356 356" fill="none" aria-label={alt}>
                <path
                    d="M263.5 319.8C164.5 379.7 15.8 320.9 13 178.3C13 87.6004 87.5 13.0004 178 13.0004C267.9 12.8004 342.3 84.0004 343 178.3C343 180.2 342.3 200.4 337.5 212.1C331.6 226.5 319.3 244.4 296.3 244.4H289.9C274.4 244.4 263.8 233.8 263.8 218.3V149.8C263.8 142.2 260 135 253.7 130.8L189.7 88.0004C182.6 83.2004 173.3 83.2004 166.2 88.0004L101.6 131.3C95.7 135.3 92.1 142 92.1 149.1V244.4"
                    stroke={currentIndex === 1 ? ColorTheme.Black.color : ColorTheme.Grey.color}
                    strokeWidth="26"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                />
            </MobileNavbarMenuSVG>
        );
    }
    if (text === "Wishlists") {
        return (
            <MobileNavbarMenuSVG width="24" height="24" viewBox="0 0 24 24" fill="none" aria-label={alt}>
                <g filter="url(#filter0_d_49605_13976)">
                    <mask id="path-1-inside-1_49605_13976" fill="white">
                        <path d="M12.2908 4.90746C12.6603 4.52202 13.0051 4.1168 13.3968 3.761C15.5124 1.83748 18.3557 1.45574 20.564 2.7838C22.8389 4.15263 23.9832 7.03113 23.306 9.75643C22.6919 12.231 21.1979 14.1841 19.5111 16.0088C17.4487 18.24 15.0663 20.1017 12.6158 21.8795C12.4626 21.9907 12.1191 22.0463 11.9918 21.9536C8.62569 19.5026 5.34855 16.9465 2.93766 13.4787C1.85764 11.927 1.0396 10.2518 1.00129 8.30607C0.950625 5.74136 2.39765 3.39409 4.57623 2.45394C6.76222 1.51009 9.40295 2.08826 11.2714 3.91914C11.5914 4.23293 11.9152 4.54302 12.2908 4.90746Z" />
                    </mask>
                    <path
                        d="M12.2908 4.90746C12.6603 4.52202 13.0051 4.1168 13.3968 3.761C15.5124 1.83748 18.3557 1.45574 20.564 2.7838C22.8389 4.15263 23.9832 7.03113 23.306 9.75643C22.6919 12.231 21.1979 14.1841 19.5111 16.0088C17.4487 18.24 15.0663 20.1017 12.6158 21.8795C12.4626 21.9907 12.1191 22.0463 11.9918 21.9536C8.62569 19.5026 5.34855 16.9465 2.93766 13.4787C1.85764 11.927 1.0396 10.2518 1.00129 8.30607C0.950625 5.74136 2.39765 3.39409 4.57623 2.45394C6.76222 1.51009 9.40295 2.08826 11.2714 3.91914C11.5914 4.23293 11.9152 4.54302 12.2908 4.90746Z"
                        stroke={currentIndex === 2 ? ColorTheme.Black.color : ColorTheme.Grey.color}
                        strokeWidth="3.2"
                        mask="url(#path-1-inside-1_49605_13976)"
                    />
                </g>
                <defs>
                    <filter id="filter0_d_49605_13976" x="-13.7" y="-11" width="54.5" height="52" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                        <feOffset dx="1.3" dy="3" />
                        <feGaussianBlur stdDeviation="8" />
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_49605_13976" />
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_49605_13976" result="shape" />
                    </filter>
                </defs>
            </MobileNavbarMenuSVG>
        );
    }
    if (text === "Trips") {
        return (
            <MobileNavbarMenuSVG width="24" height="24" viewBox="0 0 24 24" fill="none" aria-label={alt}>
                <path
                    d="M23.3055 0L24 1.43547L22.099 2.41814V22.3943C22.099 23.2405 21.4651 23.9342 20.6611 23.9952L20.5453 24H3.45465C2.63585 24 1.96465 23.3449 1.90561 22.514L1.90095 22.3943V12.855L0.694503 13.478L0 12.0426L1.90095 11.0607V4.73192H3.45465V10.2578L23.3055 0ZM20.5453 3.22098L3.45465 12.0522V22.3943H8.11575V14.366C8.11575 13.5198 8.74966 12.8261 9.5537 12.7643L9.66945 12.7603H14.3305C15.1493 12.7603 15.8205 13.4154 15.8804 14.2463L15.8842 14.366V22.3943H20.5453V3.22098ZM14.3305 14.366H9.66945V22.3943H14.3305V14.366Z"
                    fill={currentIndex === 3 ? ColorTheme.Black.color : ColorTheme.Grey.color}
                />
            </MobileNavbarMenuSVG>
        );
    }
    if (text === "Messages") {
        return (
            <MobileNavbarMenuSVG width="24" height="24" viewBox="0 0 24 24" fill="none" aria-label={alt}>
                <mask id="path-mask" fill="white">
                    <path d="M0 6.24062C0 4.0562 0 2.96398 0.436 2.12965C0.819 1.39574 1.431 0.79906 2.184 0.425117C3.04 0 4.16 0 6.4 0H17.6C19.84 0 20.96 0 21.816 0.425117C22.569 0.79906 23.181 1.39574 23.564 2.12965C24 2.96398 24 4.0562 24 6.24062V13.2613C24 15.4458 24 16.5379 23.564 17.3723C23.181 18.1063 22.569 18.7029 21.816 19.0768C20.96 19.5019 19.84 19.5019 17.6 19.5019H14.245C13.413 19.5019 12.997 19.5019 12.599 19.5816C12.246 19.6522 11.904 19.7691 11.583 19.929C11.222 20.1092 10.897 20.3626 10.247 20.8694L7.066 23.3506C6.512 23.7834 6.234 23.9997 6.001 24C5.798 24 5.606 23.9102 5.479 23.7554C5.333 23.5776 5.333 23.2312 5.333 22.5384V19.5019C4.093 19.5019 3.473 19.5019 2.965 19.3691C1.584 19.0084 0.506 17.957 0.136 16.6111C0 16.1151 0 15.5105 0 14.3014V6.24062Z" />
                </mask>
                <path
                    d="M0 6.24062C0 4.0562 0 2.96398 0.436 2.12965C0.819 1.39574 1.431 0.79906 2.184 0.425117C3.04 0 4.16 0 6.4 0H17.6C19.84 0 20.96 0 21.816 0.425117C22.569 0.79906 23.181 1.39574 23.564 2.12965C24 2.96398 24 4.0562 24 6.24062V13.2613C24 15.4458 24 16.5379 23.564 17.3723C23.181 18.1063 22.569 18.7029 21.816 19.0768C20.96 19.5019 19.84 19.5019 17.6 19.5019H14.245C13.413 19.5019 12.997 19.5019 12.599 19.5816C12.246 19.6522 11.904 19.7691 11.583 19.929C11.222 20.1092 10.897 20.3626 10.247 20.8694L7.066 23.3506C6.512 23.7834 6.234 23.9997 6.001 24C5.798 24 5.606 23.9102 5.479 23.7554C5.333 23.5776 5.333 23.2312 5.333 22.5384V19.5019C4.093 19.5019 3.473 19.5019 2.965 19.3691C1.584 19.0084 0.506 17.957 0.136 16.6111C0 16.1151 0 15.5105 0 14.3014V6.24062Z"
                    stroke={currentIndex === 4 ? ColorTheme.Black.color : ColorTheme.Grey.color}
                    strokeWidth="3.2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    mask="url(#path-mask)"
                />
            </MobileNavbarMenuSVG>
        );
    }
    if (text === "Home" && alt === "Host Home button") {
        return (
            <MobileNavbarMenuSVG width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path
                    d="M9 19H15M10.8 1.4L1.8 8.4C1.3 8.8 1 9.3 1 10V21C1 22.1 1.9 23 3 23H21C22.1 23 23 22.1 23 21V10C23 9.3 22.7 8.8 22.2 8.4L13.2 1.4C12.5 0.9 11.5 0.9 10.8 1.4Z"
                    stroke={currentIndex === 6 ? ColorTheme.Black.color : ColorTheme.Grey.color}
                    strokeWidth="1.6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </MobileNavbarMenuSVG>
        );
    }
    if (text === "Inbox") {
        return (
            <MobileNavbarMenuSVG width="24" height="24" viewBox="0 0 24 24" fill="none" aria-label={alt}>
                <mask id="path-1-inside-1_54966_12034" fill="white">
                    <path d="M0 6.24062C0 4.0562 1.49012e-07 2.96398 0.435974 2.12965C0.819467 1.39574 1.43139 0.79906 2.18404 0.425117C3.03968 1.49012e-07 4.15979 0 6.4 0H17.6C19.8403 0 20.9603 1.49012e-07 21.816 0.425117C22.5687 0.79906 23.1805 1.39574 23.564 2.12965C24 2.96398 24 4.0562 24 6.24062V13.2613C24 15.4458 24 16.5379 23.564 17.3723C23.1805 18.1063 22.5687 18.7029 21.816 19.0768C20.9603 19.5019 19.8403 19.5019 17.6 19.5019H14.2449C13.4129 19.5019 12.9969 19.5019 12.5989 19.5816C12.2459 19.6522 11.9043 19.7691 11.5833 19.929C11.2216 20.1092 10.8967 20.3626 10.2469 20.8694L7.06635 23.3506C6.51156 23.7834 6.23417 23.9997 6.00072 24C5.79769 24.0003 5.60563 23.9102 5.47897 23.7554C5.33333 23.5776 5.33333 23.2312 5.33333 22.5384V19.5019C4.09337 19.5019 3.47339 19.5019 2.96472 19.3691C1.58436 19.0084 0.50616 17.957 0.136293 16.6111C8.9407e-08 16.1151 0 15.5105 0 14.3014V6.24062Z" />
                </mask>
                <path
                    d="M0 6.24062C0 4.0562 1.49012e-07 2.96398 0.435974 2.12965C0.819467 1.39574 1.43139 0.79906 2.18404 0.425117C3.03968 1.49012e-07 4.15979 0 6.4 0H17.6C19.8403 0 20.9603 1.49012e-07 21.816 0.425117C22.5687 0.79906 23.1805 1.39574 23.564 2.12965C24 2.96398 24 4.0562 24 6.24062V13.2613C24 15.4458 24 16.5379 23.564 17.3723C23.1805 18.1063 22.5687 18.7029 21.816 19.0768C20.9603 19.5019 19.8403 19.5019 17.6 19.5019H14.2449C13.4129 19.5019 12.9969 19.5019 12.5989 19.5816C12.2459 19.6522 11.9043 19.7691 11.5833 19.929C11.2216 20.1092 10.8967 20.3626 10.2469 20.8694L7.06635 23.3506C6.51156 23.7834 6.23417 23.9997 6.00072 24C5.79769 24.0003 5.60563 23.9102 5.47897 23.7554C5.33333 23.5776 5.33333 23.2312 5.33333 22.5384V19.5019C4.09337 19.5019 3.47339 19.5019 2.96472 19.3691C1.58436 19.0084 0.50616 17.957 0.136293 16.6111C8.9407e-08 16.1151 0 15.5105 0 14.3014V6.24062Z"
                    stroke={currentIndex === 7 ? ColorTheme.Black.color : ColorTheme.Grey.color}
                    strokeWidth="3.2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    mask="url(#path-1-inside-1_54966_12034)"
                />
            </MobileNavbarMenuSVG>
        );
    }
    if (text === "Calendar") {
        return (
            <MobileNavbarMenuSVG viewBox="-1 -1 32 32" height="32" width="32" stroke={currentIndex === 8 ? ColorTheme.Black.color : ColorTheme.Grey.color} fill="none" aria-label={alt}>
                <g id="calendar-mark--calendar-date-day-month-mark">
                    <path
                        id="Vector"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M3.214 4.286a2.143 2.143 0 0 0 -2.143 2.143v20.357a2.143 2.143 0 0 0 2.143 2.143h23.571a2.143 2.143 0 0 0 2.143 -2.143V6.429a2.143 2.143 0 0 0 -2.143 -2.143h-4.286"
                        strokeWidth="2"
                    />
                    <path id="Vector_2" strokeLinecap="round" strokeLinejoin="round" d="M7.5 1.071v6.429" strokeWidth="2" />
                    <path id="Vector_3" strokeLinecap="round" strokeLinejoin="round" d="M22.5 1.071v6.429" strokeWidth="2" />
                    <path id="Vector_4" strokeLinecap="round" strokeLinejoin="round" d="M7.5 4.286h10.714" strokeWidth="2" />
                    <g id="Group 2567">
                        <path id="Vector_5" strokeLinecap="round" strokeLinejoin="round" d="M7.5 14.464a0.536 0.536 0 0 1 0 -1.071" strokeWidth="2" />
                        <path id="Vector_6" strokeLinecap="round" strokeLinejoin="round" d="M7.5 14.464a0.536 0.536 0 0 0 0 -1.071" strokeWidth="2" />
                    </g>
                    <g id="Group 2617">
                        <path id="Vector_7" strokeLinecap="round" strokeLinejoin="round" d="M15 14.464a0.536 0.536 0 0 1 0 -1.071" strokeWidth="2" />
                        <path id="Vector_8" strokeLinecap="round" strokeLinejoin="round" d="M15 14.464a0.536 0.536 0 0 0 0 -1.071" strokeWidth="2" />
                    </g>
                    <g id="Group 2619">
                        <path id="Vector_9" strokeLinecap="round" strokeLinejoin="round" d="M7.5 21.964a0.536 0.536 0 1 1 0 -1.071" strokeWidth="2" />
                        <path id="Vector_10" strokeLinecap="round" strokeLinejoin="round" d="M7.5 21.964a0.536 0.536 0 1 0 0 -1.071" strokeWidth="2" />
                    </g>
                    <g id="Group 2620">
                        <path id="Vector_11" strokeLinecap="round" strokeLinejoin="round" d="M15 21.964a0.536 0.536 0 1 1 0 -1.071" strokeWidth="2" />
                        <path id="Vector_12" strokeLinecap="round" strokeLinejoin="round" d="M15 21.964a0.536 0.536 0 1 0 0 -1.071" strokeWidth="2" />
                    </g>
                    <g id="Group 2618">
                        <path id="Vector_13" strokeLinecap="round" strokeLinejoin="round" d="M22.5 14.464a0.536 0.536 0 1 1 0 -1.071" strokeWidth="2" />
                        <path id="Vector_14" strokeLinecap="round" strokeLinejoin="round" d="M22.5 14.464a0.536 0.536 0 1 0 0 -1.071" strokeWidth="2" />
                    </g>
                </g>
            </MobileNavbarMenuSVG>
        );
    }
    if (text === "Listings") {
        return (
            <MobileNavbarMenuSVG viewBox="0 0 400.000000 400.000000" fill={currentIndex === 9 ? ColorTheme.Black.color : ColorTheme.Grey.color} aria-label={alt}>
                <g transform="translate(0.000000,400.000000) scale(0.100000,-0.100000)">
                    <path d="M105 3871 c-49 -12 -71 -28 -89 -65 -15 -31 -16 -170 -16 -1556 0 -1679 -4 -1565 63 -1605 31 -20 62 -20 1687 -20 1625 0 1656 0 1687 20 67 40 63 -74 63 1605 0 1686 5 1570 -64 1608 -29 16 -146 17 -1666 19 -899 0 -1648 -2 -1665 -6z m3145 -1621 l0 -1370 -1500 0 -1500 0 0 1370 0 1370 1500 0 1500 0 0 -1370z" />
                    <path d="M1639 3068 c-228 -184 -736 -614 -746 -632 -7 -11 -12 -38 -13 -59 -1 -112 104 -160 203 -93 l35 24 4 -367 3 -367 30 -59 c42 -83 101 -123 197 -137 42 -5 233 -8 453 -6 l380 3 54 30 c29 16 64 43 77 60 53 70 54 76 54 475 0 203 2 370 5 370 2 0 21 -11 41 -25 51 -34 94 -41 138 -20 69 33 89 126 40 184 -23 27 -671 573 -765 644 -27 20 -47 27 -80 27 -39 0 -53 -7 -110 -52z m301 -388 l180 -151 0 -449 0 -450 -370 0 -370 0 0 450 0 449 178 150 c97 82 183 150 190 150 7 1 94 -67 192 -149z" />
                    <path d="M3823 3145 c-18 -8 -42 -29 -53 -47 -20 -33 -20 -47 -20 -1326 0 -1129 -2 -1298 -15 -1326 -31 -66 81 -61 -1475 -66 -1309 -5 -1417 -6 -1446 -22 -33 -18 -64 -71 -64 -108 0 -37 29 -85 63 -105 31 -20 63 -20 1397 -23 857 -2 1396 1 1447 7 124 15 220 72 281 168 65 101 62 35 62 1468 0 1286 0 1300 -20 1333 -20 33 -72 62 -107 62 -10 -1 -33 -7 -50 -15z" />
                </g>
            </MobileNavbarMenuSVG>
        );
    }

    return null;
}

interface MobileNavbarMenuItemNavProps {
    color: string;
    url: string;
    text: string;
    alt: string;
    currentIndex: number;
}

function MobileNavbarMenuItemNav({ color, url, text, alt, currentIndex }: MobileNavbarMenuItemNavProps) {
    let navigate = useNavigate();
    const [searchFilters, setSearchFilters] = useRecoilState(searchFiltersSelector);
    const handleMobileNavbarMenuItenInteraction = () => {
        if (url === "/") {
            const searchFiltersCopy = JSON.parse(JSON.stringify(searchFilters));
            searchFiltersCopy.category = "all";
            setSearchFilters(searchFiltersCopy);
            navigate(url);
        } else {
            navigate(url);
        }
    };
    return (
        <MobileNavbarMenuItem
            onTouchEnd={(event) => {
                event.preventDefault();
                event.stopPropagation();
                handleMobileNavbarMenuItenInteraction();
            }}
            onClick={handleMobileNavbarMenuItenInteraction}
        >
            {/* <MobileNavbarMenuImage style={{ filter: color === ColorTheme.Grey.color ? "opacity(50%)" : undefined }} alt={alt} src={img} /> */}
            <MobileNavbarMenuItemSVG text={text} currentIndex={currentIndex} alt={alt} />
            <MobileNavbarMenuItemText color={color}>{text}</MobileNavbarMenuItemText>
        </MobileNavbarMenuItem>
    );
}
