import { SetterOrUpdater, useRecoilState } from "recoil";
import {
    DesktopNavbarSearchBarButtonDivider,
    DesktopNavbarSearchBarButtonIcon,
    DesktopNavbarSearchBarButtonIconSection,
    DesktopNavbarSearchBarButtonIconSectionText,
    DesktopNavbarSearchBarButtonIconWrapper,
    DesktopNavbarSearchBarButtonTextWrapper,
    DesktopNavbarSearchBarButtonWrapper,
} from "../navbarStyling";
import { useLocation } from "react-router-dom";
import { searchFiltersSelector, searchQuerySelector } from "../../../../state/selectors/searchQuery";
import { navbarSearchbarPopType } from "../../../../state/selectors/navbarToggle";

const DesktopNavbarSearchBarButton = ({
    setNavbarSearchMenuActivated,
    navbarpopdownActive,
    setNavbarMenu,
}: {
    setNavbarSearchMenuActivated: SetterOrUpdater<boolean>;
    navbarpopdownActive: boolean;
    setNavbarMenu: SetterOrUpdater<boolean>;
}) => {
    const location = useLocation();
    const [searchQuery, setSearchquery] = useRecoilState(searchQuerySelector);
    const [searchFilters, setSearchFilters] = useRecoilState(searchFiltersSelector);
    const [searchbarPopType, setSearchbarPopType] = useRecoilState(navbarSearchbarPopType);

    const miniSearchBarActive = () => {
        if (location.pathname.includes("/listing") || location.pathname.includes("/search") || location.pathname.includes("/guest-affiliate")) {
            //no need to scroll for these pages
            return true;
        } else if (location.pathname === "/") {
            //if on home page, only show search bar if scrolled down
            return true;
        } else {
            return false;
        }
    };

    const formatStartDate = () => {
        if (searchQuery.startDate) {
            return searchQuery.startDate ? new Date(searchQuery.startDate).toLocaleDateString("au", { month: "short", day: "numeric" }) : "";
        }
    };

    const formatEndDate = () => {
        if (searchQuery.endDate) {
            return searchQuery.endDate ? new Date(searchQuery.endDate).toLocaleDateString("au", { month: "short", day: "numeric" }) : "";
        }
    };

    if (!miniSearchBarActive() || navbarpopdownActive) {
        return null;
    }
    return (
        <DesktopNavbarSearchBarButtonWrapper initial={{ opacity: 0, zIndex: 1 }} animate={{ opacity: 1, zIndex: 0 }} transition={{ duration: 0.1, ease: "easeInOut" }}>
            <DesktopNavbarSearchBarButtonIconSection
                onClick={() => {
                    setNavbarMenu(false);
                    setNavbarSearchMenuActivated(true);
                    setSearchbarPopType("place");
                }}
            >
                <DesktopNavbarSearchBarButtonIconWrapper>
                    <DesktopNavbarSearchBarButtonIcon src={"https://d292awxalydr86.cloudfront.net/Universal+icons/Search.svg"} alt={"Search icon"} />
                </DesktopNavbarSearchBarButtonIconWrapper>
                <DesktopNavbarSearchBarButtonIconSectionText hasSearchQuery={!!searchQuery.title}>{searchQuery.title ? searchQuery.title : "Anywhere"}</DesktopNavbarSearchBarButtonIconSectionText>
            </DesktopNavbarSearchBarButtonIconSection>
            <DesktopNavbarSearchBarButtonDivider />
            <DesktopNavbarSearchBarButtonTextWrapper
                hasSearchQuery={!!(searchQuery.startDate && searchQuery.endDate)}
                onClick={() => {
                    setNavbarMenu(false);
                    setNavbarSearchMenuActivated(true);
                    setSearchbarPopType("date");
                }}
            >
                {searchQuery.startDate && searchQuery.endDate ? `${formatStartDate()} - ${formatEndDate()}` : "Add dates"}
            </DesktopNavbarSearchBarButtonTextWrapper>
            <DesktopNavbarSearchBarButtonDivider />
            <DesktopNavbarSearchBarButtonTextWrapper
                hasSearchQuery={!!(searchFilters.guests && searchFilters.guests > 0)}
                onClick={() => {
                    setNavbarMenu(false);
                    setNavbarSearchMenuActivated(true);
                    setSearchbarPopType("guest");
                }}
            >
                {searchFilters.guests && searchFilters.guests > 0 ? `${searchFilters.guests} guests` : "Add guests"}
            </DesktopNavbarSearchBarButtonTextWrapper>
        </DesktopNavbarSearchBarButtonWrapper>
    );
};

export default DesktopNavbarSearchBarButton;
