import { DesktopNavbarSearchFilterWrapper, DesktopNavbarSearchFilterIcon, NavbarFilterSelectedNumberBubble } from "../navbarStyling";
import { useRecoilState, useRecoilValue } from "recoil";
import { navbarSearchFilter, navbarSearchFilterNumber } from "../../../../state/selectors/navbarToggle";
import { useEffect, useState } from "react";

const DesktopNavbarFilterButton = () => {
    const [searchFilter, setSearchFilter] = useRecoilState(navbarSearchFilter);
    const [filterBtnBubbleNumber, setFilterBtnBubbleNumber] = useRecoilState(navbarSearchFilterNumber);
    const [filterSelect, setFilterSelect] = useState(0);

    useEffect(() => {
        const number = () => {
            setFilterSelect(filterBtnBubbleNumber);
        };

        number();
    }, [filterBtnBubbleNumber]);

    return (
        <DesktopNavbarSearchFilterWrapper
            onClick={() => {
                setSearchFilter(true);
            }}
        >
            <DesktopNavbarSearchFilterIcon src={"https://d292awxalydr86.cloudfront.net/Universal+icons/filters 2.svg"} alt={"filter icon"} />
            {filterSelect > 0 && <NavbarFilterSelectedNumberBubble>{filterSelect}</NavbarFilterSelectedNumberBubble>}
        </DesktopNavbarSearchFilterWrapper>
    );
};

export default DesktopNavbarFilterButton;
